import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="bg-black text-white p-5 fixed w-full z-10">
      <nav className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-3xl font-mosvita">
          Dilume
        </Link>
        <ul className="flex space-x-6">
          <li>
            <Link to="/" className="hover:text-gray-300 font-mosvita">Home</Link>
          </li>
          <li>
            <Link to="/about" className="hover:text-gray-300 font-mosvita">Our Story</Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-gray-300 font-mosvita">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;

