import React from 'react';

function Footer() {
  return (
    <footer className="bg-black text-white py-10">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
        {/* Centered Text */}
        <div className="w-full text-center md:text-left mb-5 md:mb-0">
          <a href="/faq" className="hover:text-gray-400">© Dilume™ 2024</a>
        </div>

        {/* Social Media Icons in the Right Corner */}
        <div className="flex space-x-4 md:ml-auto">
          <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <i className="fab fa-tiktok"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

