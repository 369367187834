import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function About() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.interactive-image');
      elements.forEach((element) => {
        const scrollY = window.scrollY;
        const speed = element.getAttribute('data-speed');
        element.style.transform = `translateY(${scrollY * speed}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="relative py-20 bg-black text-white">
      <Helmet>
        <title>Dilume | Our Story</title>
      </Helmet>

      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        <div>
          <h2 data-aos="fade-up" className="text-5xl font-bold font-mosvita mb-10">
            Our Story
          </h2>
          <p data-aos="fade-up" className="text-lg font-mosvita mb-5">
            Dilume was born from a personal loss, inspiring us to help others stay connected and make health a part of everyday life. We realized the importance of health monitoring for the entire family, including pets.
          </p>
          <p data-aos="fade-up" className="text-lg font-mosvita mb-5">
            With years in the entertainment and fashion industries, we knew that style matters. People want products that function well and fit seamlessly into their lifestyle. So, we created Dilume—a health tracker that is not only functional but also a beautiful, stylish accessory.
          </p>
          <p data-aos="fade-up" className="text-lg font-mosvita mb-5">
            Dilume brings health into the lives of families worldwide. It looks and feels like a luxury item, blending effortlessly into any wardrobe or even onto a pet’s collar. It’s about making health stylish, simple, and something you carry with pride.
          </p>
          <p data-aos="fade-up" className="text-lg font-mosvita mb-5">
            More than that, Dilume connects to an app that brings friends, families, and communities together. Track health, set goals, and engage in friendly competitions. The app provides educational insights, group challenges, and shared achievements, making health a social and engaging experience.
          </p>
          <p data-aos="fade-up" className="text-lg font-mosvita mb-5">
            Dilume is about creating a supportive network where health becomes a celebration. Let’s make well-being a beautiful part of life, for everyone, everywhere.
          </p>
        </div>
        <div data-aos="fade-up">
          <img
            src={`${process.env.PUBLIC_URL}/img/wow3.webp`}
            alt="Our Story Image"
            className="w-full h-auto rounded-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default About;

