import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      {/* Hero Section */}
      <section
        className="relative h-screen flex flex-col justify-center items-center text-white font-mosvita"
        style={{
          position: 'relative',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontFamily: 'Mosvita-Regular, sans-serif',
        }}
      >
        <video
          className="absolute inset-0 w-full h-full object-cover"
          style={{
            position: 'absolute',
            inset: 0,
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
          src={`${process.env.PUBLIC_URL}/videos/creative-branding-studio.mp4`}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div
          className="relative z-10 w-48 h-48 overflow-hidden rounded-full gradient-animation"
          style={{
            position: 'relative',
            zIndex: 10,
            width: '12rem',
            height: '12rem',
            overflow: 'hidden',
            borderRadius: '50%',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/circle_tex18.png`}
            alt="Orb"
            className="absolute inset-0 w-full h-full object-cover"
            style={{
              position: 'absolute',
              inset: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <h1 data-aos="fade-up" className="text-6xl mt-10">
          Dilume
        </h1>
        <p data-aos="fade-up" className="text-xl mt-5">
          The Future of Family Health
        </p>
      </section>

      {/* Features/Intro Section */}
<section
  className="relative min-h-screen bg-black text-white py-20 font-mosvita"
  style={{
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: 'black',
    color: 'white',
    padding: '5rem 0',
    fontFamily: 'Mosvita-Regular, sans-serif',
  }}
>
  <div
    className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-10 items-center"
    style={{
      margin: '0 auto',
      padding: '0 1rem',
      display: 'grid',
      gap: '2.5rem',
      alignItems: 'center',
    }}
  >
    <div data-aos="fade-right">
      <h2 className="text-5xl font-bold mb-6">Our Story</h2>
      <p className="text-lg mb-5">
        Born from a personal journey, Dilume is our solution for families
        and friends to monitor and improve wellness together in a stylish
        and engaging way.
      </p>
      <p className="text-lg">
        We blend health, design, and technology to create engaging and visually stunning wellness experiences.
      </p>
    </div>
    <div data-aos="fade-left">
      <img
        src={`${process.env.PUBLIC_URL}/img/wow2.webp`}
        alt="About Image"
        className="w-full object-cover rounded-lg"
      />
    </div>
  </div>
</section>


{/* Our Journey Section */}
<section
  className="relative min-h-screen py-40 text-white font-mosvita"
  style={{
    position: 'relative',
    minHeight: '100vh',
    padding: '10rem 0',
    color: 'white',
    fontFamily: 'Mosvita-Regular, sans-serif',
  }}
>
  <img
    className="absolute inset-0 w-full h-full object-cover"
    style={{
      position: 'absolute',
      inset: 0,
      width: '100vw',
      height: '100vh',
      objectFit: 'cover',
    }}
    src={`${process.env.PUBLIC_URL}/img/wow4.webp`}
    alt="Our Journey Background"
  />
  {/* Decorative Images */}
  <img
    src={`${process.env.PUBLIC_URL}/img/circle_tex18.png`}
    alt="Top Right Decoration"
    className="absolute top-0 right-0 w-40 h-40 transform translate-x-1/2 -translate-y-1/2"
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: '10rem',
      height: '10rem',
      transform: 'translate(50%, -50%)',
    }}
  />
  <img
    src={`${process.env.PUBLIC_URL}/img/circle_tex18.png`}
    alt="Bottom Left Decoration"
    className="absolute bottom-0 left-0 w-40 h-40 transform -translate-x-1/2 translate-y-1/2"
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '10rem',
      height: '10rem',
      transform: 'translate(-50%, 50%)',
    }}
  />
  <div
    className="container mx-auto px-4 relative z-10"
    style={{
      margin: '0 auto',
      padding: '0 1rem',
      position: 'relative',
      zIndex: 10,
    }}
  >
    <h2 className="text-5xl font-bold mb-6">Our Journey</h2>
    <p className="text-xl mb-10">
      Our journey has been all about discovering new ways to bring the future of health into every home.
    </p>
  </div>
</section>


      {/* Call to Action Section */}
      <section
        className="relative h-screen flex flex-col justify-center items-center text-white font-mosvita"
        style={{
          position: 'relative',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontFamily: 'Mosvita-Regular, sans-serif',
        }}
      >
        <video
          className="absolute inset-0 hero-video"
          style={{
            position: 'absolute',
            inset: 0,
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
          src={`${process.env.PUBLIC_URL}/videos/vecteezy_colorful-gradient-transition-for-background.mp4`}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className="relative z-10 text-center">
          <h2
            data-aos="fade-up"
            className="text-5xl font-bold mb-10"
          >
            Join Us on the Journey to Better Health
          </h2>
          <form
            action="https://formspree.io/f/xovqqvwv"
            method="POST"
            data-aos="fade-up"
            className="flex flex-col items-center"
          >
            <input
              type="email"
              name="email"
              required
              placeholder="Enter your email"
              className="p-4 border-b border-white bg-transparent text-white mb-4"
            />
            <button
              type="submit"
              className="p-4 bg-gradient-to-br from-gray-800 to-black text-white font-bold rounded-md hover:opacity-90 transition-opacity duration-200"
            >
              Get on the Waitlist
            </button>
          </form>
        </div>
      </section>

    {/* Statistics Section with Six Orbs */}
<section
  className="relative min-h-screen bg-black text-white py-20 font-mosvita"
  style={{
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: 'black',
    color: 'white',
    padding: '5rem 0',
    fontFamily: 'Mosvita-Regular, sans-serif',
  }}
>
  <div
    className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-10 items-center justify-items-center"
    style={{
      margin: '0 auto',
      padding: '0 1rem',
    }}
  >
    {[
      "60% of U.S. pets are overweight.",
      "39 million children under 5 obese (2020).",
      "42% of U.S. adults are obese.",
      "Group workouts improve adherence by 20%.",
      "Social support reduces stress by 26%.",
      "Group weight loss success increased by 10%.",
    ].map((stat, index) => (
      <div key={index} className="orb-container relative">
        <div
          className="orb-bounce w-48 h-48 md:w-32 md:h-32 rounded-full flex items-center justify-center text-center p-4"
          style={{
            width: '12rem',
            height: '12rem',
            overflow: 'hidden',
            borderRadius: '50%',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/circle_tex18.png`}
            alt="Orb Image"
            className="absolute inset-0 w-full h-full object-cover"
            style={{
              borderRadius: '50%',
            }}
          />
          <p
            className="text-xs sm:text-xs md:text-sm lg:text-base font-bold text-black relative z-10 px-2"
            style={{
              position: 'relative',
              zIndex: 10,
              color: 'black',
              padding: '1rem',
              fontWeight: 'bold',
              fontSize: 'calc(0.8rem + 0.5vw)',
            }}
          >
            {stat}
          </p>
        </div>
      </div>
    ))}
  </div>
</section>
    </div>
  );
}

export default Home;



