import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.interactive-image');
      elements.forEach((element) => {
        const scrollY = window.scrollY;
        const speed = element.getAttribute('data-speed');
        element.style.transform = `translateY(${scrollY * speed}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="relative py-20 bg-black text-white">
      <Helmet>
        <title>Dilume | Contact</title>
      </Helmet>
      {/* Decorative Images */}
      <img
        src={`${process.env.PUBLIC_URL}/img/circle_tex18.png`}
        alt="Top Right Decoration"
        className="absolute top-0 right-0 w-32 h-32 transform translate-x-1/2 -translate-y-1/2 interactive-image"
        data-speed="0.2"
      />

      {/* Form Section */}
      <div className="flex flex-col items-center w-full">
        <h2 data-aos="fade-up" className="text-5xl font-bold font-mosvita mb-10">Get in Touch</h2>
        <form
          action="https://formspree.io/f/xldereoy"
          method="POST"
          className="flex flex-col gap-8 w-full max-w-lg"
        >
          <div className="w-full">
            <label className="block text-lg font-mosvita mb-2">Your Name</label>
            <input
              type="text"
              name="name"
              required
              className="w-full p-4 border-b border-white bg-transparent text-white font-mosvita"
            />
          </div>
          <div className="w-full">
            <label className="block text-lg font-mosvita mb-2">Your Email</label>
            <input
              type="email"
              name="email"
              required
              className="w-full p-4 border-b border-white bg-transparent text-white font-mosvita"
            />
          </div>
          <div className="w-full">
            <label className="block text-lg font-mosvita mb-2">Message</label>
            <textarea
              name="message"
              rows="4"
              required
              className="w-full p-4 border-b border-white bg-transparent text-white font-mosvita"
            ></textarea>
          </div>
          <button
            type="submit"
            className="p-4 mt-4 bg-gradient-to-br from-black to-gray-800 text-white font-bold font-mosvita rounded-md hover:opacity-90 transition-opacity duration-200"
          >
            Contact Us
          </button>
        </form>
      </div>

      {/* Images Section */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/img/contact-california-1000-632.jpg`}
            alt="Dilume, New York"
            className="w-full h-64 object-cover rounded-lg"
          />
          <p className="text-center mt-4 font-mosvita">Dilume, New York</p>
        </div>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/img/contact-new-york-1000-632.jpg`}
            alt="Dilume, London"
            className="w-full h-64 object-cover rounded-lg"
          />
          <p className="text-center mt-4 font-mosvita">Dilume, London</p>
        </div>
      </div>
    </section>
  );
}

export default Contact;


